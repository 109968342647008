<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading
            :active="isActive"
            spinner="bar-fade-scale"
            color="#428BCA"
          ></vue-element-loading>
          <input-form-text
            textInput="Titulo"
            :required="true"
            v-model="form.title"
            :value="form.title"
          ></input-form-text>

          <input-form-text
            textInput="Localização"
            :required="true"
            v-model="form.location"
            :value="form.location"
          ></input-form-text>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right"></label>
            <div class="col-sm-9 bandeiras">
              <div @click.prevent="form.location = '🇨🇦'">🇨🇦</div>
              <div @click.prevent="form.location = '🇸🇬'">🇸🇬</div>
              <div @click.prevent="form.location = '🇺🇸'">🇺🇸</div>
              <div @click.prevent="form.location = '🇹🇼'">🇹🇼</div>
              <div @click.prevent="form.location = '🇦🇺'">🇦🇺</div>
            </div>
          </div>
          <input-form-text
            textInput="Data"
            :required="true"
            v-model="form.datemission"
            :value="form.datemission"
          ></input-form-text>
          <input-form-text
            textInput="Missão"
            :required="true"
            v-model="form.mission"
            :value="form.mission"
          ></input-form-text>
          <!-- <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right"></label>
            <div class="col-sm-9">
              <select v-model="select">
                <option value="">Selecione</option>
                <option v-for="(r, k) in regexs" :key="k">{{r}}</option>
              </select>
            </div>
          </div> -->
          <div class="form-group">
            <label
              class="col-sm-3 control-label no-padding-right"
              for="content"
            >
              Descrição</label
            >
            <div class="col-sm-8">
              <textarea
                class="form-control autosize"
                v-model="form.content"
                placeholder="Descrição"
                rows="4"
              ></textarea>
            </div>
            <!-- <textarea class="form-control autosize" v-model="form.content" placeholder="Descrição" v-autosize></textarea></div> -->
          </div>
        </div>
      </div>
      <footer-button :disabled="isActive"></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { sucesso, erro } from '@/components/Message'
import moment from 'moment'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Missões',
      form: {
        title: '',
        location: '',
        datemission: moment().format('DD/MM'),
        mission: '',
        content: ''
      },
      location: '',
      isActive: false,
      select: '',
      regexs: []
    }
  },
  watch: {
    select() {
      this.getContent()
    },
    'form.content'() {
      this.getContentFull()
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    async gravar() {
      this.isActive = true
      const body = this.form
      if (this.$route.params.id) {
        await Services.put(`/missoes/${this.$route.params.id}`, body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/sistema/missoes')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      } else {
        await Services.post('/missoes', body)
          .then(() => {
            sucesso('Atualizado...')
            this.form.title = ''
            this.form.location = ''
            this.form.mission = ''
            this.form.content = ''

            //this.$router.push('/sistema/missoes')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      }
      this.isActive = false
    },
    async getRegexs() {
      const dados = await Services.get('/regex/all')
      this.regexs = dados.map(i => i.title)
    },
    async getDados() {
      const dados = await Services.get(
        `/missoes/${this.$route.params.id}`
      ).catch(response => {
        console.log('error: ' + response)
        return (this.isActive = false)
      })
      this.form.title = dados.title
      this.form.location = dados.location
      this.form.datemission = dados.datemission
      this.form.mission = dados.mission
      this.form.content = dados.content
      this.isActive = false
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    },
    async getContentFull() {
      this.regexs.map(async i => {
        let pattern = new RegExp(i, 'gm')
        const content = await this.form.content.matchAll(pattern)
        console.log(content, pattern)
        let dados = await Array.from(content)
        console.log(
          dados,
          dados.length,
          dados[0].findIndex(
            i =>
              i === '🇹🇼' || i === '🇸🇬' || i === '🇨🇦' || i === '🇺🇸' || i === '🇦🇺'
          )
        )
        window.dados = dados
        if (dados.length) {
          const location = dados[0].findIndex(
            i =>
              i === '🇹🇼' || i === '🇸🇬' || i === '🇨🇦' || i === '🇺🇸' || i === '🇦🇺'
          )
          let position = [1, 2, 3]
          position.splice(location - 1, 1)
          dados = dados.map(i => {
            return {
              location: i[location],
              title: i[position[0]],
              mission: i[position[1]]
            }
          })
          this.form.title = dados[0].title
          this.form.location = dados[0].location
          this.form.mission = dados[0].mission
        }
      })
    },
    async getContent() {
      let pattern = new RegExp(this.select, 'gm')
      const content = await this.form.content.matchAll(pattern)
      console.log(content, pattern)
      let dados = await Array.from(content)
      console.log(
        dados,
        dados.length,
        dados[0].findIndex(
          i =>
            i === '🇹🇼' || i === '🇸🇬' || i === '🇨🇦' || i === '🇺🇸' || i === '🇦🇺'
        )
      )
      window.dados = dados
      if (dados.length) {
        const location = dados[0].findIndex(
          i =>
            i === '🇹🇼' || i === '🇸🇬' || i === '🇨🇦' || i === '🇺🇸' || i === '🇦🇺'
        )
        let position = [1, 2, 3]
        position.splice(location - 1, 1)
        dados = dados.map(i => {
          return {
            location: i[location],
            title: i[position[0]],
            mission: i[position[1]]
          }
        })
        this.form.title = dados[0].title
        this.form.location = dados[0].location
        this.form.mission = dados[0].mission
      }
    }
  },
  mounted() {
    this.verifPermissao()
    this.getRegexs()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped>
.bandeiras {
  font-size: 25px;
  display: flex;
  div {
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>
